.modal {
   overflow-y: auto;
}

.modal-open {
   overflow: auto;
}

.modal-open[style] {
   padding-right: 0px !important;
}
